import SoftwareSideMenu, {
  MenuItemType,
  PageType,
} from "components/software-sidemenu"

import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Layout from "components/layout"
import React from "react"
import Row from "react-bootstrap/Row"
import SEO from "components/seo"
import SoftwareBreadcrumb from "components/software-sidemenu/breadcrumb"
import useTranslations from "components/use-translations"

export default function SikSorulanSorular() {
  const {
    genel: { sikSorulanSorular },
    magaza: {
      soru1,
      cevap1,
      soru2,
      cevap2,
      soru3,
      cevap3,
      soru4,
      cevap4,
      soru5,
      cevap5,
      soru6,
      cevap6,
      soru7,
      cevap7,
      soru8,
      cevap8,
    },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title="Kütüphane Programı - Sık Sorulan Sorular - Tusunami Kütüphane Takip"
        description="Kütüphane programı hakkında sık sorulan soruları içeren sayfa"
        pathname="https://www.kodvizit.com/kutuphane-programi/sik-sorulan-sorular"
        datePublished="2017-02-09T22:22:55+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
        breadcrumbList={[
          {
            path: "https://www.kodvizit.com/kutuphane-programi",
            name: "Kütüphane Programı",
          },
        ]}
      />
      <Container className="mt-2 mb-5">
        <SoftwareBreadcrumb
          parentPage={PageType.Kutuphane}
          activeMenuItem={MenuItemType.SikSorulanSorular}
        />
        <Row>
          <Col>
            <h1 className="mb-4">{sikSorulanSorular}</h1>
            <Accordion>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <strong>{soru1}</strong>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>{cevap1}</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  <strong>{soru2}</strong>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>{cevap2}</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  <strong>{soru3}</strong>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>{cevap3}</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="3">
                  <strong>{soru4}</strong>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>{cevap4}</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="4">
                  <strong>{soru5}</strong>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>{cevap5}</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="5">
                  <strong>{soru6}</strong>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>{cevap6}</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="6">
                  <strong>{soru7}</strong>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>{cevap7}</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="7">
                  <strong>{soru8}</strong>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="7">
                  <Card.Body>{cevap8}</Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
          <Col md="3">
            <SoftwareSideMenu
              parentPage={PageType.Kutuphane}
              menuItems={[
                MenuItemType.Ozellikler,
                MenuItemType.SikSorulanSorular,
                MenuItemType.EkranGoruntuleri,
                MenuItemType.KullanimKilavuzu,
                MenuItemType.Referanslar,
              ]}
              activeMenuItem={MenuItemType.SikSorulanSorular}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
